.navbar-container {
  background-color: #5dacbd;
}

/* Media query for viewport width > 576px */
@media (min-width: 576px) {
  .navbar-container {
    padding-left: 40px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
